import React from "react";

import AppStoreBadgeFr from "./images/app-store-fr.svg";
import PlayStoreBadgeFr from "./images/google-play-fr.svg";
import AppStoreBadgeEn from "./images/app-store-en.svg";
import PlayStoreBadgeEn from "./images/google-play-en.svg";

import screenHomeFr from "./images/screen_home_fr.jpg";
import screenHistoryFr from "./images/screen_history_fr.jpg";
import screenStatsFr from "./images/screen_stats_fr.jpg";
import screenHomeEn from "./images/screen_home_en.jpg";
import screenHistoryEn from "./images/screen_history_en.jpg";
import screenStatsEn from "./images/screen_stats_en.jpg";

const fr = {
  seo: {
    description:
      "My PrEP, l'app qui accompagne votre PrEP : rappels, suivi de la protection et des rapports, historique, statistiques… Disponible sur iPhone et Android.",
  },
  iconAlt: "Icône de My PrEP",
  subtitles: [
    "L’app qui suit votre PrEP,",
    "L’app qui garde l’historique de vos prises,",
    "L’app qui respecte votre vie privée,",
    "L’app qui calcule votre état de protection,",
    "L’app qui vous dit combien de comprimés prendre,",
    "L’app qui vous rappelle de prendre votre PrEP,",
    "L’app qui vous protège.",
  ],
  stores: {
    note: "4,7/5",
    apple: {
      title: "Télécharger My PrEP sur l’App Store",
      url: "https://apps.apple.com/fr/app/my-prep/id1456478617",
      img: AppStoreBadgeFr,
    },
    google: {
      title: "Télécharger My PrEP sur le Play Store",
      url: "https://play.google.com/store/apps/details?id=fr.ntag.myprep.android",
      img: PlayStoreBadgeFr,
    },
  },
  featureNotif: {
    title: "Rappels de prise",
    content: (
      <>
        My PrEP vous <b>notifie</b> lorsque c’est l’heure de prendre un
        comprimé, et ce, toutes les 15 minutes jusqu’à ce que vous ayez pris
        celui-ci : plus possible d’être en retard sur une prise !<br />
        Les rappels fonctionnent même en <b>mode avion</b> et lorsque vous
        changez de fuseau horaire 😎.
      </>
    ),
  },
  featureStatus: {
    title: "Calcul de votre statut",
    content: (
      <>
        À tout moment My PrEP vous indique à partir de quand et{" "}
        <b>jusqu’à quelle date</b> vos rapports sont protégés par la PrEP. Plus
        de doute possible 👌.
        <br />
        L’app calcule également le nombre de comprimés à prendre : plus aucune
        question à se poser.
      </>
    ),
  },
  featureIntercourses: {
    title: "Suivi de vos rapports",
    content: (
      <>
        My PrEP vous permet également de suivre <b>vos rapports sexuels</b>
         : indiquez la date et l’heure de chaque rapport, et visualisez d’un
        coup d’œil son état de protection par la PrEP.
        <br />
        Vous pouvez également ajouter une note ainsi que le ou les partenaires
        impliqués : très pratique pour savoir qui contacter en cas de MST !
      </>
    ),
  },
  featureHistory: {
    title: "Historique",
    content: (
      <>
        My PrEP vous donne accès à <b>l’historique</b> de toutes vos prises, et
        votre état passé de protection, ainsi qu’à l’historique complet de vos
        rapports, à travers une timeline détaillée.
        <br />
        Vous pouvez aussi accéder à la liste des rapports avec un partenaire
        donné.
      </>
    ),
  },
  featurePrivacy: {
    title: "Vie privée",
    content: (
      <>
        Toutes les données de My PrEP sont stockées <b>en local</b> dans votre
        téléphone. L’application ne communique pas avec internet et fonctionne
        parfaitement en mode avion.
        <br />
        En outre il n’y a aucun tracker, ni cookie, ni suivi dans l’app. Vous
        êtes anonymes et <b>vos données restent dans votre téléphone</b>.
      </>
    ),
  },
  featureStats: {
    title: "Statistiques",
    content: (
      <>
        La partie <b>Statistiques</b> vous permet de suivre votre utilisation de
        la My PrEP (nombre total de comprimés, durée moyenne d’une session
        PrEP…) et vous donne également accès à des chiffres sur vos rapports
        sexuels 😏.
      </>
    ),
  },
  screens: [
    { src: screenHomeFr, alt: "Screenshot de l’écran d’accueil de My PrEP" },
    { src: screenHistoryFr, alt: "Screenshot de l’historique" },
    { src: screenStatsFr, alt: "Screenshot des statistiques" },
  ],
  footer: {
    about: "Conçu avec ❤ à Paris",
    contact: "Écrire au développeur",
    guide: {
      text: "Guide PrEP en PDF",
      title:
        "La PrEP : mode d’emploi, un guide éditer par Aides. Comment ça marche, quels sont les schémas, quand la prendre ?",
      url: "https://myprep.app/guide_prep.pdf",
    },
  },
  switchLanguage: {
    title: "The app is also available in English!",
    text: "English version",
  },
};

const en = {
  seo: {
    description:
      "My PrEP, the app that tracks your PrEP: reminders, protection status, history of intercourses, statistics… Available on iPhone and Android.",
  },
  iconAlt: "My PrEP app icon",
  subtitles: [
    "It tracks your PrEP,",
    "It remembers when you took your pills,",
    "It respects your privacy,",
    "It computes your protection status,",
    "It tells you how many pills to take,",
    "It reminds you to take your PrEP,",
    "It protects you.",
  ],
  stores: {
    note: "4.7/5",
    apple: {
      title: "Download My PrEP on the App Store",
      url: "https://apps.apple.com/us/app/my-prep/id1456478617",
      img: AppStoreBadgeEn,
    },
    google: {
      title: "Download My PrEP on the Play Store",
      url: "https://play.google.com/store/apps/details?id=fr.ntag.myprep.android",
      img: PlayStoreBadgeEn,
    },
  },
  featureNotif: {
    title: "Reminders",
    content: (
      <>
        My PrEP sends you a <b>notification</b> when it’s time to tike your
        PrEP, and this, every 15 minutes until you take your pill: impossible to
        miss a dose anymore!
        <br />
        The reminders also work in <b>flight mode</b> and when your timezone
        changes 😎.
      </>
    ),
  },
  featureStatus: {
    title: "Protection status",
    content: (
      <>
        At any time My PrEP tells you from when and <b>until when</b> your
        reports are protected by PrEP. No more doubts 👌.
        <br />
        The app also computes the number of pills to take: no more questions to
        ask.
      </>
    ),
  },
  featureIntercourses: {
    title: "Intercourses",
    content: (
      <>
        My PrEP allows you to <b>track your sexual activity</b>: enter the date
        and time of each intercourse, and see at a glimpse its PrEP protection
        status.
        <br />
        You can also add a personal note and your partners: especially handy in
        case of an STD!
      </>
    ),
  },
  featureHistory: {
    title: "History",
    content: (
      <>
        My PrEP gives you access to the <b>full history</b> of your doses, your
        previous protection status, and your complete sexual activity, through a
        detailed and clear timeline.
        <br />
        You can also check the intercourses you had with a specific partner.
      </>
    ),
  },
  featurePrivacy: {
    title: "Privacy",
    content: (
      <>
        All of My PrEP data is stored <b>locally on your phone</b>. The app does
        not exchange data over the internet and works perfectly fine in flight
        mode.
        <br />
        Moreover, there is no tracker, no cookie, no analytics in the app. You
        are anonymous and <b>your data stays inside your phone</b>.
      </>
    ),
  },
  featureStats: {
    title: "Statistics",
    content: (
      <>
        The <b>Statistics</b> part allows you to track how you use PrEP (how
        many pills, duration of a session…) and gives you also access to figures
        about your sexual activity 😏.
      </>
    ),
  },
  screens: [
    { src: screenHomeEn, alt: "Screenshot of My PrEP home tab" },
    { src: screenHistoryEn, alt: "Screenshot of the history tab" },
    { src: screenStatsEn, alt: "Screenshot of the stats tab" },
  ],
  footer: {
    about: "Made with ❤ in Paris",
    contact: "Reach the developer",
    guide: {
      text: "PrEP: guide & instructions",
      title:
        "Guide about PrEP schemas: how it works, what are the schemas, when to take it…",
      url: "https://myprep.app/guide_prep_en.pdf",
    },
  },
  switchLanguage: {
    title: "L’app est aussi disponible en français !",
    text: "Version française",
  },
};

const locales = { fr, en };

export default locales;
