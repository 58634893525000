import styled from "styled-components";

export const Body = styled.div`
  background: linear-gradient(#c23e37, #601f1b);
  color: white;
  padding-bottom: 10rem;
  min-height: 100vh;
`;

export const LanguageContainer = styled.a`
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 0.7;
  text-decoration: none;
  padding: 8px 16px;

  transition: opacity 0.3s;

  :hover {
    opacity: 1;
  }
`;

export const HeaderContainer = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  font-family: "Varela Round";
  text-align: center;
  margin-top: 5rem;
  font-size: 6rem;
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
  transition: opacity 0.5s;
  pointer-events: none;
  z-index: 10;

  @media only screen and (max-width: 560px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 4rem;
    margin-top: 2rem;
  }
`;

export const Header = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  :hover ${Title} {
    opacity: 0.3;
  }
`;

export const Icon = styled.div`
  background-color: #c23e37;
  border-radius: 16px;
  overflow: hidden;
  height: 100px;
  width: 100px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;

  @media only screen and (max-width: 560px) {
    height: 80px;
    width: 80px;
  }
`;

export const Subtitles = styled.div`
  text-align: left;
  display: inline-block;
  margin-top: -12rem;

  @media only screen and (max-width: 560px) {
    margin-top: -10rem;
  }
`;

export const Subtitle = styled.h2`
  font-family: "Roboto Slab";
  font-size: 2rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
  transition: opacity 0.2s;
  line-height: 2.1rem;
  white-space: nowrap;
  max-width: 100vw;

  &:nth-child(1) {
    opacity: 0.05;
  }
  &:nth-child(2) {
    opacity: 0.1;
  }
  &:nth-child(3) {
    opacity: 0.15;
  }
  &:nth-child(4) {
    opacity: 0.2;
  }
  &:nth-child(5) {
    opacity: 0.25;
  }
  &:nth-child(6) {
    opacity: 0.3;
  }
  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: 560px) {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }
`;

export const Stores = styled.div`
  text-align: center;
  margin: 3rem 0px;

  img {
    height: 60px;
    margin: 1rem;
  }
`;

export const StoresNote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 2rem;
`;

export const StoresNoteStar = styled.div`
  color: yellow;
`;

export const StoresNoteText = styled.div`
  margin-left: 1rem;
  font-weight: bold;
  font-family: "Varela Round";
  padding-top: 0.1rem;
  font-size: 2.2rem;
`;

export const Features = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Feature = styled.div`
  flex: 1;
  margin: 2rem;
`;

export const FeatureTitle = styled.h3`
  padding: 0;
  margin: 0;
  font-family: "Raleway";
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
`;

export const FeatureContent = styled.div`
  text-align: justify;
  padding: 1rem;
`;

export const Screens = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  img {
    width: 28%;
    border-radius: 32px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;

    img {
      width: 70%;
      margin-bottom: 2rem;
    }
  }
`;

export const Footer = styled.div`
  background: linear-gradient(#601f1b, #250c0a);
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
`;
