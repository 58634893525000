import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChartBar,
  faBell,
  faCheckCircle,
  faHeart,
  faListAlt,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "./global.css";

import * as S from "./styles";
import Seo from "../../components/seo";

import iconSmall from "./images/icon-small.png";

import allLocales from "./locales";

library.add(
  faChartBar,
  faBell,
  faCheckCircle,
  faHeart,
  faListAlt,
  faEye,
  faStar
);

const Feature = ({ title, icon, children }) => {
  return (
    <S.Feature>
      <S.FeatureTitle>
        <FontAwesomeIcon icon={icon} style={{ height: "2rem" }} /> {title}
      </S.FeatureTitle>
      <S.FeatureContent>{children}</S.FeatureContent>
    </S.Feature>
  );
};

const IndexPage = (props) => {
  const { lang, canRedirect } = props.pageContext;
  const locales = allLocales[lang];

  if (canRedirect && typeof navigator !== "undefined") {
    if (!navigator.language.startsWith("fr")) {
      window.location.href = "/en/";
    }
  }

  return (
    <>
      <Seo description={locales.seo.description} />
      <S.Body>
        <S.LanguageContainer
          title={locales.switchLanguage.title}
          href={`/${lang === "en" ? "fr" : "en"}`}
          data-nosnippet
        >
          {locales.switchLanguage.text}
        </S.LanguageContainer>
        <S.HeaderContainer>
          <S.Header>
            <S.Title>
              <S.Icon>
                <img
                  src={iconSmall}
                  alt={locales.iconAlt}
                  height="70"
                  width="70"
                />
              </S.Icon>
              My PrEP
            </S.Title>
            <S.Subtitles>
              {locales.subtitles.map((subtitle, index) => (
                <S.Subtitle
                  key={index}
                  style={
                    index === locales.subtitles.length - 1
                      ? { textAlign: "center" }
                      : null
                  }
                >
                  {subtitle}
                </S.Subtitle>
              ))}
            </S.Subtitles>
          </S.Header>
        </S.HeaderContainer>
        <S.Stores>
          <a title={locales.stores.apple.title} href={locales.stores.apple.url}>
            <img src={locales.stores.apple.img} alt="App Store" />
          </a>
          <a
            title={locales.stores.google.title}
            href={locales.stores.google.url}
          >
            <img src={locales.stores.google.img} alt="Play Store" />
          </a>
          <S.StoresNote>
            <S.StoresNoteStar>
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
            </S.StoresNoteStar>
            <S.StoresNoteText>{locales.stores.note}</S.StoresNoteText>
          </S.StoresNote>
        </S.Stores>
        <S.Features>
          <Feature title={locales.featureNotif.title} icon={faBell}>
            {locales.featureNotif.content}
          </Feature>
          <Feature title={locales.featureStatus.title} icon={faCheckCircle}>
            {locales.featureStatus.content}
          </Feature>
          <Feature title={locales.featureIntercourses.title} icon={faHeart}>
            {locales.featureIntercourses.content}
          </Feature>
        </S.Features>
        <S.Features>
          <Feature title={locales.featureHistory.title} icon={faListAlt}>
            {locales.featureHistory.content}
          </Feature>
          <Feature title={locales.featurePrivacy.title} icon={faEye}>
            {locales.featurePrivacy.content}
          </Feature>
          <Feature title={locales.featureStats.title} icon={faChartBar}>
            {locales.featureStats.content}
          </Feature>
        </S.Features>
        <S.Screens>
          {locales.screens.map((screen, index) => (
            <img src={screen.src} alt={screen.alt} key={index} />
          ))}
        </S.Screens>
      </S.Body>
      <S.Footer>
        <a title={locales.footer.guide.title} href={locales.footer.guide.url}>
          {locales.footer.guide.text}
        </a>
        <div>{locales.footer.about}</div>
        <a title={locales.footer.contact} href="mailto:contact@myprep.app">
          contact@myprep.app
        </a>
      </S.Footer>
    </>
  );
};

export default IndexPage;
